import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
  CardTitle,
  Modal, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledTooltip
} from "reactstrap";
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
const Dashboard = props => {

  //meta title
  document.title = "Dashboard | AeroDrop";

  const [singlebtn, setSinglebtn] = useState(false)
  const [singlebtn1, setSinglebtn1] = useState(false)
  const [singlebtn2, setSinglebtn2] = useState(false)
  const [singlebtn3, setSinglebtn3] = useState(false)


  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_large, setmodal_large] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [modal_small, setmodal_small] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [togModal, setTogModal] = useState(false);
  const [togModal1, setTogModal1] = useState(false);
  const [varyingModal, setVaryingModal] = useState(false);
  const [modal_1, setModal_1] = useState("varying");

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  function tog_small() {
    setmodal_small(!modal_small);
    removeBodyCss();
  }

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }

  function tog_toggleModal() {
    setTogModal(!togModal);
    setTogModal1(false)
    removeBodyCss();
  }

  function tog_toggleModal1() {
    setTogModal1(!togModal1);
    setTogModal(false)
    removeBodyCss();
  }

  function tog_varyingModal() {
    setVaryingModal(!varyingModal);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Wallets")}
          />
          <>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Group..."
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="text-sm-end">
                          <button onClick={() => {
                          tog_center();
                        }}
                            type="button"
                            className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                          >
                            <i className="mdi mdi-plus me-1" /> Create Wallet Group
                          </button>
                          <Modal
                          isOpen={modal_center}
                          toggle={() => {
                            tog_center();
                          }}
                          centered
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0">Create Wallet Group</h5>
                            <button
                              type="button"
                              onClick={() => {
                                setmodal_center(false);
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form className="">
                              <div className="mb-3">
                                <label className="form-label form-label">Choose Wallet Group</label>
                                <input
                                  name="username"
                                  placeholder="Enter Group Name"
                                  type="text"
                                  className="form-control"
                                  aria-invalid="false"
                                  defaultValue=""
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Select Blockchain</label>
                                <select className="form-control">
                                  <option>Select Blockchain</option>
                                  <option>ETH</option>
                                </select>
                              </div>
                              <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="submit" className="btn btn-primary">
                                  Create Group
                                </button>{" "}
                                <button type="reset" className="btn btn-secondary">
                                  Cancel
                                </button>
                              </div>
                            </form>

                          </div>
                        </Modal>
                        </div>
                      </div>
                      {/* end col*/}
                    </div>
                    {/* end row */}
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}

            <div className="row">
              <div className="col-xl-3 col-sm-6">
                <div className="card border-active">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-4">
                        <div className="avatar-sm">
                          <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                            <i className="mdi mdi-bitcoin h2 text-warning mb-0" />
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="text-truncate font-size-15">
                          <a href="#" className="text-dark">
                            Group1
                          </a>
                        </h5>
                        <p className="text-muted mb-2">BTC Wallet</p>
                        <h5 className="mb-0">1.02356 BTC</h5>
                      </div>
                      <div className="dropdown ms-auto">
                        <Dropdown
                          isOpen={singlebtn}
                          toggle={() => setSinglebtn(!singlebtn)}
                        >
                          <DropdownToggle className="btn nav-btn bg-transparent text-muted border-0 p-0 lh-1">
                            <i className="mdi mdi-dots-horizontal fs-4" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>Copy Addresses</DropdownItem>
                            <DropdownItem>Edit</DropdownItem>
                            <DropdownItem>Duplicate</DropdownItem>
                            <DropdownItem>Bulk Import</DropdownItem>
                            <DropdownItem>Export All</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 py-3 border-top">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item me-2">
                        <a className="btn btn-primary btn-sm" onClick={() => {
                          tog_center();
                        }}>Bulk Create</a>
                        <Modal
                          isOpen={modal_center}
                          toggle={() => {
                            tog_center();
                          }}
                          centered
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0">Bulk Wallet Creation</h5>
                            <button
                              type="button"
                              onClick={() => {
                                setmodal_center(false);
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form className="">
                              <div className="mb-3">
                                <label className="form-label form-label">How many wallets would you  like to create</label>
                                <input
                                  name="username"
                                  placeholder=""
                                  type="text"
                                  className="form-control"
                                  aria-invalid="false"
                                  defaultValue=""
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Would you like to use a prefix?</label>
                                <input
                                  name="username"
                                  placeholder=""
                                  type="text"
                                  className="form-control"
                                  aria-invalid="false"
                                  defaultValue=""
                                />
                              </div>
                              <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="submit" className="btn btn-primary">
                                  Generate
                                </button>{" "}
                                <button type="reset" className="btn btn-secondary">
                                  Cancel
                                </button>
                              </div>
                            </form>

                          </div>
                        </Modal>
                      </li>
                      <li className="list-inline-item">
                        <a className="btn btn-warning btn-sm">Collect Funds </a>
                      </li>
                      <li className="list-inline-item float-end">
                        <a href="#">
                          <i className="mdi mdi-trash-can text-danger font-size-18" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="card border-active">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-4">
                        <div className="avatar-sm">
                          <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                            <i className="mdi mdi-ethereum h2 text-primary mb-0" />
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="text-truncate font-size-15">
                          <a href="#" className="text-dark">
                            Group2
                          </a>
                        </h5>
                        <p className="text-muted mb-2">ETH Wallet</p>
                        <h5 className="mb-0">1.02356 ETH</h5>
                      </div>
                      <div className="dropdown ms-auto">
                        <Dropdown
                          isOpen={singlebtn1}
                          toggle={() => setSinglebtn1(!singlebtn1)}
                        >
                          <DropdownToggle className="btn nav-btn bg-transparent text-muted border-0 p-0 lh-1">
                            <i className="mdi mdi-dots-horizontal fs-4" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>Copy Addresses</DropdownItem>
                            <DropdownItem>Edit</DropdownItem>
                            <DropdownItem>Duplicate</DropdownItem>
                            <DropdownItem>Bulk Import</DropdownItem>
                            <DropdownItem>Export All</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 py-3 border-top">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item me-2">
                        <a className="btn btn-primary btn-sm">Bulk Create</a>
                      </li>
                      <li className="list-inline-item">
                        <a className="btn btn-warning btn-sm">Collect Funds </a>
                      </li>
                      <li className="list-inline-item float-end">
                        <a href="#">
                          <i className="mdi mdi-trash-can text-danger font-size-18" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="card border-active">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-4">
                        <div className="avatar-sm">
                          <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                            <i className="mdi mdi-bitcoin h2 text-warning mb-0" />
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="text-truncate font-size-15">
                          <a href="#" className="text-dark">
                            Group3
                          </a>
                        </h5>
                        <p className="text-muted mb-2">BTC Wallet</p>
                        <h5 className="mb-0">1.02356 BTC</h5>
                      </div>
                      <div className="dropdown ms-auto">
                        <Dropdown
                          isOpen={singlebtn2}
                          toggle={() => setSinglebtn2(!singlebtn2)}
                        >
                          <DropdownToggle className="btn nav-btn bg-transparent text-muted border-0 p-0 lh-1">
                            <i className="mdi mdi-dots-horizontal fs-4" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>Copy Addresses</DropdownItem>
                            <DropdownItem>Edit</DropdownItem>
                            <DropdownItem>Duplicate</DropdownItem>
                            <DropdownItem>Bulk Import</DropdownItem>
                            <DropdownItem>Export All</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 py-3 border-top">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item me-2">
                        <a className="btn btn-primary btn-sm">Bulk Create</a>
                      </li>
                      <li className="list-inline-item">
                        <a className="btn btn-warning btn-sm">Collect Funds </a>
                      </li>
                      <li className="list-inline-item float-end">
                        <a href="#">
                          <i className="mdi mdi-trash-can text-danger font-size-18" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="card border-active">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-4">
                        <div className="avatar-sm">
                          <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                            <i className="mdi mdi-ethereum h2 text-primary mb-0" />
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="text-truncate font-size-15">
                          <a href="#" className="text-dark">
                            Group4
                          </a>
                        </h5>
                        <p className="text-muted mb-2">ETH Wallet</p>
                        <h5 className="mb-0">1.02356 ETH</h5>
                      </div>
                      <div className="dropdown ms-auto">
                        <Dropdown
                          isOpen={singlebtn3}
                          toggle={() => setSinglebtn3(!singlebtn3)}
                        >
                          <DropdownToggle className="btn nav-btn bg-transparent text-muted border-0 p-0 lh-1">
                            <i className="mdi mdi-dots-horizontal fs-4" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>Copy Addresses</DropdownItem>
                            <DropdownItem>Edit</DropdownItem>
                            <DropdownItem>Duplicate</DropdownItem>
                            <DropdownItem>Bulk Import</DropdownItem>
                            <DropdownItem>Export All</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 py-3 border-top">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item me-2">
                        <a className="btn btn-primary btn-sm">Bulk Create</a>
                      </li>
                      <li className="list-inline-item">
                        <a className="btn btn-warning btn-sm">Collect Funds </a>
                      </li>
                      <li className="list-inline-item float-end">
                        <a href="#">
                          <i className="mdi mdi-trash-can text-danger font-size-18" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Wallets Information</h4>
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th className="align-middle">ID</th>
                            <th className="align-middle">Wallet Name</th>
                            <th className="align-middle">Wallet Address</th>
                            <th className="align-middle">Balance</th>
                            <th className="align-middle">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <a
                                href="#"
                                className="text-body fw-bold"
                              >
                                #1
                              </a>{" "}
                            </td>
                            <td>Wallet 1</td>
                            <td>
                              OxE1136a604dcf3OxE1136a604dcf3OxE1136a6{" "}
                             <a href="#"> <i className="bx bx-copy text-info" /></a>
                            </td>
                            <td>0.0001ETH</td>
                            <td>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i id="send" className="text-warning bx bx-send font-size-16" />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="send"
                                >
                                  Send
                                </UncontrolledTooltip>
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i id="browser" className="text-info bx bx-globe font-size-16" />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="browser"
                                >
                                  Browser
                                </UncontrolledTooltip>
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info bx bx-loader-alt font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-primary bx bx-detail font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info mdi mdi-pencil font-size-16 " />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-danger mdi mdi-delete font-size-16" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a
                                href="#"
                                className="text-body fw-bold"
                              >
                                #2
                              </a>{" "}
                            </td>
                            <td>Wallet 2</td>
                            <td>
                              OxE1136a604dcf3OxE1136a604dcf3OxE1136a6{" "}
                              <a href="#"> <i className="bx bx-copy text-info" /></a>
                            </td>
                            <td>0.0000ETH</td>
                            <td>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i id="send" className="text-warning bx bx-send font-size-16" />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="send"
                                >
                                  Send
                                </UncontrolledTooltip>
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info bx bx-globe font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info bx bx-loader-alt font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-primary bx bx-detail font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info mdi mdi-pencil font-size-16 " />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-danger mdi mdi-delete font-size-16" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a
                                href="#"
                                className="text-body fw-bold"
                              >
                                #3
                              </a>{" "}
                            </td>
                            <td>Wallet 3</td>
                            <td>
                              OxE1136a604dcf3OxE1136a604dcf3OxE1136a6{" "}
                              <a href="#"> <i className="bx bx-copy text-info" /></a>
                            </td>
                            <td>0.0000ETH</td>
                            <td>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i id="send" className="text-warning bx bx-send font-size-16" />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="send"
                                >
                                  Send
                                </UncontrolledTooltip>
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info bx bx-globe font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info bx bx-loader-alt font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-primary bx bx-detail font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info mdi mdi-pencil font-size-16 " />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-danger mdi mdi-delete font-size-16" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a
                                href="#"
                                className="text-body fw-bold"
                              >
                                #4
                              </a>{" "}
                            </td>
                            <td>Wallet 4</td>
                            <td>
                              OxE1136a604dcf3OxE1136a604dcf3OxE1136a6{" "}
                              <a href="#"> <i className="bx bx-copy text-info" /></a>
                            </td>
                            <td>0.0000ETH</td>
                            <td>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i id="send" className="text-warning bx bx-send font-size-16" />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="send"
                                >
                                  Send
                                </UncontrolledTooltip>
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info bx bx-globe font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info bx bx-loader-alt font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-primary bx bx-detail font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info mdi mdi-pencil font-size-16 " />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-danger mdi mdi-delete font-size-16" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a
                                href="#"
                                className="text-body fw-bold"
                              >
                                #5
                              </a>{" "}
                            </td>
                            <td>Wallet 5</td>
                            <td>
                              OxE1136a604dcf3OxE1136a604dcf3OxE1136a6{" "}
                              <a href="#"> <i className="bx bx-copy text-info" /></a>
                            </td>
                            <td>0.0000ETH</td>
                            <td>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i id="send" className="text-warning bx bx-send font-size-16" />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="send"
                                >
                                  Send
                                </UncontrolledTooltip>
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info bx bx-globe font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info bx bx-loader-alt font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-primary bx bx-detail font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info mdi mdi-pencil font-size-16 " />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-danger mdi mdi-delete font-size-16" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a
                                href="#"
                                className="text-body fw-bold"
                              >
                                #6
                              </a>{" "}
                            </td>
                            <td>Wallet 6</td>
                            <td>
                              OxE1136a604dcf3OxE1136a604dcf3OxE1136a6{" "}
                              <a href="#"> <i className="bx bx-copy text-info" /></a>
                            </td>
                            <td>0.0000ETH</td>
                            <td>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i id="send" className="text-warning bx bx-send font-size-16" />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="send"
                                >
                                  Send
                                </UncontrolledTooltip>
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info bx bx-globe font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info bx bx-loader-alt font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-primary bx bx-detail font-size-16" />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-info mdi mdi-pencil font-size-16 " />
                              </a>
                              <a
                                href="#"
                                className="me-2 btn btn-light btn-sm"
                              >
                                <i className="text-danger mdi mdi-delete font-size-16" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* end table-responsive */}
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
          </>



        </Container>
      </div>
    </React.Fragment>
  );
};


export default withTranslation()(Dashboard);
